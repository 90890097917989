// 01.00 - Import statements and external dependencies
import React, { useState } from 'react';
import numeral from 'numeral';
import { formatValue } from './formatUtils';

// 02.00 - DataTable Component Definition
const DataTable = ({ 
  data = [], 
  propertyData = [], 
  sortConfig, 
  handleSort, 
  highlightedFeatureId, 
  setHighlightedFeatureId, 
  map, 
  setSelectedProperty 
}) => {

  // 02.01 - Log to confirm data received for debugging purposes
  console.log("Rendering DataTable with propertyData:", propertyData);

  // 02.02 - Group data by category, excluding the 'search_link' column (Zillow Link)
  const groupedData = data.reduce((acc, item) => {
    if (item['Field Name'] !== 'search_lin') {
      if (!acc[item.Category]) {
        acc[item.Category] = [];
      }
      acc[item.Category].push(item);
    }
    return acc;
  }, {});

  // 03.00 - Define state variables for pagination and category collapse
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 50;  // Number of rows per page
  const [collapsedCategories, setCollapsedCategories] = useState(Object.keys(groupedData));

  // 03.01 - Sort property data based on sortConfig
  const sortedPropertyData = [...propertyData].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  // 03.02 - Toggle collapse for a category in the table
  const toggleCategoryCollapse = (category) => {
    setCollapsedCategories(prevState =>
      prevState.includes(category)
        ? prevState.filter(c => c !== category)
        : [...prevState, category]
    );
  };

  // 03.03 - Get sort indicator for the column header
  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '▲' : '▼';
    }
    return '';
  };

  // 04.00 - Pagination logic to calculate current rows
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedPropertyData.slice(indexOfFirstRow, indexOfLastRow);

  // 04.01 - Handle pagination
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

// 05.00 - Import formatValue utility function


// Use the formatValue function from the utility file in the relevant sections


  // 06.00 - Render DataTable UI with grouped columns and rows
  return (
    <div style={{ overflowX: 'scroll', overflowY: 'hidden', width: '100%', display: 'block' }}>
      {/* 06.01 - Table rendering */}
      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
        {/* 06.02 - Render table headers for categories */}
        <thead>
          <tr>
            {Object.keys(groupedData).map((category) => (
              <th
                key={category}
                colSpan={groupedData[category].length}
                style={{ border: '1px solid black', padding: '8px' }}
              >
                <button onClick={() => toggleCategoryCollapse(category)}>
                  {collapsedCategories.includes(category) ? '+' : '-'} {category}
                </button>
              </th>
            ))}
          </tr>
          {/* 06.03 - Render column headers within each category */}
          <tr>
            {Object.keys(groupedData).map((category) =>
              groupedData[category].map((col, index) => (
                !collapsedCategories.includes(category) || index === 0 ? (
                  <th
                    key={col['Field Name']}
                    colSpan={collapsedCategories.includes(category) && index === 0 ? groupedData[category].length : 1}
                    style={{ border: '1px solid black', padding: '8px', cursor: 'pointer' }}
                    onClick={() => handleSort(col['Field Name'])}
                  >
                    {col['Name as it appears on the website']} {getSortIndicator(col['Field Name'])}
                  </th>
                ) : null
              ))
            )}
          </tr>
        </thead>

        {/* 06.04 - Render table body with property rows */}
        <tbody>
          {currentRows.length > 0 ? (
            currentRows.map((property, rowIndex) => (
              <tr
                key={rowIndex}
                className={property.id === highlightedFeatureId ? 'highlighted-row' : ''}
                style={property.id === highlightedFeatureId ? { backgroundColor: '#ffdd57' } : {}}
                onClick={(event) => {
                  // Prevent row click when an address link is clicked
                  if (event.target.tagName.toLowerCase() === 'a') return;
                
                  console.log('Row clicked:', property.id);
                  if (!map) {
                    console.error("Map instance is not available in DataTable.");
                    return;
                  }
                  if (!map.isStyleLoaded()) {
                    console.error("Map style is not fully loaded.");
                    return;
                  }
                  if (!map.getLayer('centroid_layer')) {
                    console.error("'centroid_layer' does not exist in the map's style.");
                    return;
                  }
                
                  // If all conditions pass, apply the color change
                  setHighlightedFeatureId(property.id);
                  setSelectedProperty(property);  // Set clicked property for sidebar display
                  map.setPaintProperty('centroid_layer', 'circle-color', [
                    'case',
                    ['==', ['get', 'id'], property.id], '#FF0000',  // Red for clicked feature
                    ['==', ['get', 'j_fact_2'], null], 'rgba(255, 255, 255, 0)',  // Transparent for null values
                    ['<', ['get', 'j_fact_2'], -50], '#00296B',  // Dark Blue for values < -50
                    ['>', ['get', 'j_fact_2'], 50], '#FFD700',  // Yellow for values > 50
                    ['interpolate', ['linear'], ['get', 'j_fact_2'], -100, '#00296B', 0, '#808080', 100, '#FFD700']  // Color ramp
                  ]);
                }}
                
              >
                {/* 06.04.01 - Render data cells for each category and column */}
                {Object.keys(groupedData).map((category) =>
                  groupedData[category].map((col, index) => (
                    !collapsedCategories.includes(category) || index === 0 ? (
<td
  key={col['Field Name']}
  colSpan={collapsedCategories.includes(category) && index === 0 ? groupedData[category].length : 1}
  style={{ border: '1px solid black', padding: '8px' }}
>
  {col['Field Name'] === 'situsaddre' && property['search_lin']
    ? <a
        href={property['search_lin']}  // Correct field name
        target="_blank"
        rel="noopener noreferrer"
        onClick={(event) => event.stopPropagation()}  // Prevent row click interference
      >
        {formatValue(property[col['Field Name']], col['Num Format'], col['Prefix'], col['Suffix'], col['Data Type'], col['Field Name'], property)}
      </a>
    : formatValue(property[col['Field Name']], col['Num Format'], col['Prefix'], col['Suffix'], col['Data Type'], col['Field Name'], property)
  }
</td>

                    ) : null
                  ))
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={Object.keys(groupedData).reduce((acc, cat) => acc + groupedData[cat].length, 0)}
                style={{ textAlign: 'center', padding: '8px' }}
              >
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* 06.05 - Pagination Controls */}
      <div style={{ padding: '10px', textAlign: 'center', width: '100%' }}>
        {[...Array(Math.ceil(sortedPropertyData.length / rowsPerPage))].map((_, index) => (
          <button
            key={index + 1}
            onClick={() => paginate(index + 1)}
            style={{ margin: '0 5px', padding: '5px 10px' }}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

// 07.00 - Export DataTable Component
export default DataTable;
