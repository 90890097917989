// 01.00 - Import statements and external dependencies
import React from 'react';
import DataTable from './DataTable';  // Component that renders the table of filter results or data
import numeral from 'numeral';  // Library for number formatting
import PropertyDetails from './PropertyDetails';
import { applyFilters } from './filterUtils';
import { formatValue } from './formatUtils';

// 02.00 - FilterResultsSidebar Component Definition
const FilterResultsSidebar = ({
  data,
  propertyData,
  sortConfig,
  handleSort,
  map,
  highlightedFeatureId,
  setHighlightedFeatureId,
  selectedProperty,
  setSelectedProperty,
  groupedData,
  setPropertyData,
  isLayerLoaded  // Add this line to include the new prop
}) => {


  // 03.00 - Component rendering and conditional content
  return (
    <div 
      style={{ 
        boxSizing: 'border-box',  // Ensure content fits within its box
        overflow: 'hidden',  // Hide overflow content
        display: 'flex',  // Use flexbox layout
        flexDirection: 'column',  // Arrange children in a column
        height: '100vh',  // Full viewport height
        backgroundColor: '#f4f4f4',  // Light grey background
        padding: '10px'  // Add padding around content
      }}
    >
      {/* 03.01 - Heading for sidebar, changes based on whether a property is selected */}
      <h2>{selectedProperty ? 'Property Details' : 'Filter Results'}</h2>

      {/* 03.02 - Conditional rendering for selected property or data table */}
      {selectedProperty ? (
        // 03.02.01 - Render PropertyDetails component if a property is selected
        <PropertyDetails 
          selectedProperty={selectedProperty} 
          groupedData={groupedData} 
          setSelectedProperty={setSelectedProperty} 
          formatValue={formatValue} 
        />
      ) : (
        // 03.02.02 - If no property is selected, show filtered property data
        propertyData && propertyData.length > 0 ? (
          <div 
            style={{ 
              flexGrow: '1',  // Allow this section to grow
              overflowX: 'auto',  // Enable horizontal scrolling
              overflowY: 'hidden',  // Disable vertical scrolling
              width: '800px',  // Fixed width for the data table
              display: 'flex',  // Use flexbox layout
              flexDirection: 'column'  // Arrange children in a column
            }}
          >
            {/* 03.02.03 - Render DataTable component */}
            <DataTable 
  data={data} 
  propertyData={propertyData} 
  sortConfig={sortConfig} 
  handleSort={handleSort} 
  map={map}  
  highlightedFeatureId={highlightedFeatureId}
  isLayerLoaded={isLayerLoaded}  // Pass layer load state to sidebar

  setHighlightedFeatureId={setHighlightedFeatureId}  
  setSelectedProperty={setSelectedProperty}  // Pass setSelectedProperty to DataTable
/>

          </div>
        ) : (
          // 03.02.04 - Show message if no property data is available
          <p>No property data available</p>
        )
      )}
    </div>
  );
};

// 04.00 - Export FilterResultsSidebar component
export default FilterResultsSidebar;
