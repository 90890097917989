import React, { useEffect } from 'react';
import './Sidebar.css';
import { useSidebar } from './SidebarContext';
import FilterAccordion from './FilterAccordion';

const Sidebar = ({ applyFilters, resetFilter, map, setPropertyData }) => {
  const { isCollapsed, toggleSidebar } = useSidebar();

  console.log("Rendering Sidebar. Current isCollapsed:", isCollapsed); // Log render with state

  // Add an effect to handle map resize after the sidebar transition ends
  useEffect(() => {
    const sidebarElement = document.querySelector('.sidebar');

    // Function to handle the transition end event
    const handleTransitionEnd = () => {
      if (map) {
        console.log("Resizing map after sidebar transition."); // Log resize action
        map.resize();
      }
    };

    // Add the transition end event listener
    sidebarElement.addEventListener('transitionend', handleTransitionEnd);

    // Cleanup event listener on component unmount
    return () => {
      sidebarElement.removeEventListener('transitionend', handleTransitionEnd);
    };
  }, [map, isCollapsed]); // Depend on map and isCollapsed

  return (
    <div className={`sidebar ${isCollapsed ? 'collapsed' : 'expanded'}`}>
      <div className="toggle-button-container">
        <button className="toggle-button" onClick={() => {
          console.log("Toggle button clicked."); // Log button click
          toggleSidebar();
        }}>
          {isCollapsed ? '→' : '←'}
        </button>
      </div>
      
      {!isCollapsed && (
        <FilterAccordion 
          applyFilters={(filters) => applyFilters(filters, map, setPropertyData)} 
          resetFilter={(key) => resetFilter(key, map)} 
        />
      )}
    </div>
  );
};

export default Sidebar;
