// src/addGeoServerLayers.js

/**
 * Adds GeoServer source and layers to the Mapbox instance.
 * @param {Object} mapInstance - The Mapbox map instance.
 */
export function addGeoServerLayers(mapInstance) {
    if (!mapInstance.getSource('sql_statement')) {
      mapInstance.addSource('sql_statement', {
        type: 'geojson',
        data: 'https://consciouscommunities.me/geoserver/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=ne:sql_statement&outputFormat=application/json',
        generateId: true,  // Ensure unique feature IDs
      });
    }
  
    if (!mapInstance.getLayer('sql_statement_dots')) {
      mapInstance.addLayer({
        id: 'sql_statement_dots',
        type: 'circle',
        source: 'sql_statement',
        minzoom: 0,
        maxzoom: 24,
        paint: {
          'circle-radius': 5,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#000000',
          'circle-color': [
            'case',
            ['==', ['get', 'j_fact_2'], null], 'rgba(255, 255, 255, 0)',
            ['<', ['get', 'j_fact_2'], -50], '#00296B',
            ['>', ['get', 'j_fact_2'], 50], '#FFD700',
            ['interpolate', ['linear'], ['get', 'j_fact_2'], -50, '#00296B', 0, '#808080', 50, '#FFD700']
          ]
        }
      });
    }
  
    if (!mapInstance.getLayer('sql_statement_symbols')) {
      mapInstance.addLayer({
        id: 'sql_statement_symbols',
        type: 'symbol',
        source: 'sql_statement',
        minzoom: 13,
        layout: {
          'text-field': [
            'concat',
            '$',
            ['case',
              ['>=', ['to-number', ['get', 'list_price']], 1000000], ['concat', ['slice', ['to-string', ['/', ['get', 'list_price'], 1000000]], 0, 3], 'm'],
              ['>=', ['to-number', ['get', 'list_price']], 100000], ['concat', ['slice', ['to-string', ['get', 'list_price']], 0, 3], 'k'],
              ['to-string', ['get', 'list_price']]
            ]
          ],
          'text-size': 15,
          'text-anchor': 'left',
          'text-offset': [.75, 0],
          'text-allow-overlap': false,
        },
        paint: {
          'text-color': '#000000',
          'text-halo-color': '#FFFFFF',
          'text-halo-width': 12,
        },
      });
    }
  }
  