import React, { useState, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import { applyFilters, resetFilter } from './filterUtils';
import FilterResultsSidebar from './FilterResultsSidebar';
import 'mapbox-gl/dist/mapbox-gl.css';
import './App.css';
import Sidebar from './Sidebar';
import { SidebarProvider, useSidebar } from './SidebarContext';

import { useMapbox } from './useMapbox';
import { usePropertyData } from './usePropertyData';
import { useInitializeMap } from './useInitializeMap';

// Your Mapbox token
mapboxgl.accessToken = 'pk.eyJ1IjoiZGF2aWRjb25zY2lvdXNjb21tdW5pdGllc2NvbSIsImEiOiJjbTBlNWE4aHQwazd1MnFxNDd0c2gzMmhyIn0.NKXrXJz2Mfz8mloCqTDGaQ';

function App() {
  const [propertyData, setPropertyData] = useState([]);
  const [highlightedFeatureId, setHighlightedFeatureId] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const { toggleSatelliteView } = useMapbox();
  const { data, groupedData } = usePropertyData();
  const { map } = useInitializeMap(setPropertyData, setHighlightedFeatureId, setSelectedProperty, propertyData);
  const { isCollapsed } = useSidebar(); // Get the isCollapsed state from SidebarContext

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Effect to trigger map resize when sidebar collapses or expands
  useEffect(() => {
    if (map) {
      map.resize(); // Call resize when isCollapsed changes
    }
  }, [map, isCollapsed]); // Depend on map and isCollapsed

  return (
    <SidebarProvider> {/* Wrap the components in SidebarProvider */}
      <div className="main-container">
        <Sidebar 
          applyFilters={applyFilters} 
          resetFilter={resetFilter} 
          map={map} 
          setPropertyData={setPropertyData} 
        />

        <div 
          id="map" 
          className="map-container"
          style={{ 
            flex: 1, 
            position: 'relative' 
          }} 
        >
          <button 
            onClick={() => toggleSatelliteView(map)}  
            style={{ 
              position: 'absolute', 
              top: '10px', 
              right: '10px', 
              zIndex: 1, 
              padding: '10px 15px', 
              backgroundColor: '#007BFF', 
              color: '#FFFFFF', 
              border: 'none', 
              borderRadius: '4px', 
              cursor: 'pointer' 
            }}
          >
            Toggle Satellite View
          </button>
        </div>

        <div 
          className="filter-results" 
          style={{ 
            flexBasis: '300px',  
            backgroundColor: '#f4f4f4', 
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            overflowY: 'auto'
          }}
        >
          <FilterResultsSidebar 
            data={data} 
            propertyData={propertyData} 
            sortConfig={sortConfig} 
            handleSort={handleSort} 
            map={map} 
            highlightedFeatureId={highlightedFeatureId}
            setHighlightedFeatureId={setHighlightedFeatureId} 
            selectedProperty={selectedProperty}  
            setSelectedProperty={setSelectedProperty}
            groupedData={groupedData} 
            setPropertyData={setPropertyData}  
          />
        </div>
      </div>
    </SidebarProvider>
  );
}

export default App;
