// 01.00 - Import statements and external dependencies
import React from 'react';

// 02.00 - PropertyDetails Component Definition
const PropertyDetails = ({ selectedProperty, groupedData, setSelectedProperty, formatValue }) => {
  // 02.01 - Render back button and property details
  return (
    <>
      {/* 02.01.01 - Back to Results button */}
      <button 
        onClick={() => setSelectedProperty(null)} 
        style={{ marginBottom: '10px', padding: '8px 16px', cursor: 'pointer' }}
      >
        Back to Results
      </button>

      {/* 02.02 - Loop through grouped data by category and render property details */}
      {Object.keys(groupedData).map(category => (
        <div key={category}>
          {/* 02.02.01 - Category heading */}
          <h3>{category}</h3>

          {/* 02.02.02 - Two-column layout for property fields */}
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {/* 02.02.02.01 - Left column (first half of fields) */}
            <ul style={{ margin: '0', padding: '0', flex: '1', listStyle: 'none' }}>
              {groupedData[category].slice(0, Math.ceil(groupedData[category].length / 2)).map(col => (
                <li key={col['Field Name']} style={{ marginBottom: '4px', lineHeight: '1.2' }}>
                  <strong>{col['Name as it appears on the website']}: </strong>
                  {formatValue(
                    selectedProperty[col['Field Name']],
                    col['Num Format'],  
                    col['Prefix'],      
                    col['Suffix'],      
                    col['Data Type'],   
                    col['Field Name'],  
                    selectedProperty    
                  )}
                </li>
              ))}
            </ul>

            {/* 02.02.02.02 - Right column (second half of fields) */}
            <ul style={{ margin: '0', padding: '0', flex: '1', listStyle: 'none' }}>
              {groupedData[category].slice(Math.ceil(groupedData[category].length / 2)).map(col => (
                <li key={col['Field Name']} style={{ marginBottom: '4px', lineHeight: '1.2' }}>
                  <strong>{col['Name as it appears on the website']}: </strong>
                  {formatValue(
                    selectedProperty[col['Field Name']],
                    col['Num Format'],  
                    col['Prefix'],      
                    col['Suffix'],      
                    col['Data Type'],   
                    col['Field Name'],  
                    selectedProperty    
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </>
  );
};

// 03.00 - Export PropertyDetails Component
export default PropertyDetails;
