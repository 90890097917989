// FilterAccordion.js

import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { FaTrash } from 'react-icons/fa';
import RangeSlider from './RangeSlider';
import './FilterAccordion.css';
import { createFilterExpression } from './filterUtils';

const FilterAccordion = ({ applyFilters, resetFilter }) => {
  const [filters, setFilters] = useState({});
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    Papa.parse('/ColumnsNames-ColumnsNames.csv', {
      download: true,
      header: true,
      complete: (result) => {
        const filterableData = result.data.filter((row) => row.Filterable === 'TRUE');
        const initialFilters = filterableData.reduce((acc, curr) => {
          const min = curr.Range ? Number(curr.Range.split('-')[0]) : 0;
          const max = curr.Range ? Number(curr.Range.split('-')[1]) : 100;
          const sliderIncrement = curr['Slider Increment'] ? Number(curr['Slider Increment']) : 1;

          acc[curr['Field Name']] = {
            min,
            max,
            selectedMin: min,
            selectedMax: max,
            applied: false,
            category: curr.Category,
            label: curr['Name as it appears on the website'],
            userInputType: curr['User Input Type'],
            options: curr.Range ? curr.Range.split(', ') : [],
            selectedOptions: [],
            dataType: curr['Data Type'],
            prefix: curr.Prefix || '', 
            suffix: curr.Suffix || '', 
            numFormat: curr['Num Format'] || '0,0',
            sliderIncrement
          };
          return acc;
        }, {});

        setFilters(initialFilters);
        setActiveTab(Object.keys(initialFilters)[0]);
      },
    });
  }, []);

  const handleSliderChange = (key, newValue) => {
    if (!newValue || newValue.length < 2) return;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: {
        ...prevFilters[key],
        selectedMin: Number(newValue[0]),
        selectedMax: Number(newValue[1]),
      },
    }));
  };

  const handleCheckboxChange = (key, value) => {
    setFilters((prevFilters) => {
      const isSelected = prevFilters[key].selectedOptions.includes(value);
      const updatedOptions = isSelected
        ? prevFilters[key].selectedOptions.filter((option) => option !== value)
        : [...prevFilters[key].selectedOptions, value];

      return {
        ...prevFilters,
        [key]: {
          ...prevFilters[key],
          selectedOptions: updatedOptions,
        },
      };
    });
  };

  const applyFilter = (key) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, [key]: { ...prevFilters[key], applied: true } };
      const activeFilters = Object.keys(updatedFilters)
        .filter((k) => updatedFilters[k].applied)
        .map((k) => createFilterExpression(updatedFilters[k], k));

      const combinedFilter = activeFilters.length > 1 ? ["all", ...activeFilters] : activeFilters[0];
      applyFilters(updatedFilters, combinedFilter);

      return updatedFilters;
    });
  };

  const resetSingleFilter = (key) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: { 
        ...prevFilters[key], 
        selectedMin: prevFilters[key].min,
        selectedMax: prevFilters[key].max,
        selectedOptions: [],
        applied: false  
      },
    }));
  };

  const categories = Array.from(new Set(Object.values(filters).map(filter => filter.category)));

  return (
    <div className="accordion">
      <div className="filter-tabs">
        {categories.map((category) => (
          <button 
            key={category} 
            className={`filter-tab ${activeTab === category ? 'active' : ''}`}
            onClick={() => setActiveTab(category)}
          >
            {category}
          </button>
        ))}
      </div>

      {Object.keys(filters)
        .filter(key => filters[key].category === activeTab)
        .map(key => (
          <div className="filter-item" key={key}>
            <label>{filters[key].label}</label>

            {filters[key].userInputType === 'Range Slider' && (
              <RangeSlider
                filter={filters[key]}
                handleSliderChange={handleSliderChange}
                sliderKey={key}
              />
            )}

            {filters[key].userInputType === 'Choose Any' && (
              <div className="filter-checkbox">
                {filters[key].options.map(option => (
                  <div key={option}>
                    <input
                      type="checkbox"
                      checked={filters[key].selectedOptions.includes(option)}
                      onChange={() => handleCheckboxChange(key, option)}
                    />
                    <label>{option}</label>
                  </div>
                ))}
              </div>
            )}

            {filters[key].userInputType === 'Yes/No' && (
              <div className="filter-yesno">
                <label>
                  <input
                    type="radio"
                    name={`filter-${key}`}
                    value="yes"
                    checked={filters[key].selectedOptions[0] === 1}
                    onChange={() => handleCheckboxChange(key, 1)}
                  />
                  Yes
                </label>
                <label>
                  <input
                    type="radio"
                    name={`filter-${key}`}
                    value="no"
                    checked={filters[key].selectedOptions[0] === null || filters[key].selectedOptions[0] === 0}
                    onChange={() => handleCheckboxChange(key, null)}
                  />
                  No
                </label>
              </div>
            )}

            <div className="filter-actions">
              <button onClick={() => applyFilter(key)}>Apply</button>
              <FaTrash onClick={() => resetSingleFilter(key)} />
            </div>
          </div>
        ))}
    </div>
  );
};

export default FilterAccordion;
