// Utility function to handle filter expressions
export const createFilterExpression = (filter, key) => {
  console.log('Filter being processed:', filter);  // Log filter details
  let finalFilter = null;
  const dataType = filter.dataType;
  const selectedMin = parseFloat(filter.selectedMin);
  const selectedMax = parseFloat(filter.selectedMax);

  if (dataType === 'String' && filter.userInputType === 'Choose Any') {
    finalFilter = [
      "any",
      ...filter.selectedOptions.map(option => ["==", ["get", key], option])
    ];
  } else if (dataType === 'String' && filter.userInputType === 'Text') {
    finalFilter = ["==", ["get", key], filter.selectedText];
  } else if ((dataType === 'Integer' || dataType === 'Double') && filter.userInputType === 'Range Slider') {
    finalFilter = [
      "all",
      [">=", ["number", ["get", key]], selectedMin],
      ["<=", ["number", ["get", key]], selectedMax]
    ];
  } else if (dataType === 'Boolean' && filter.userInputType === 'Yes/No') {
    console.log("Selected Options for Yes/No:", filter.selectedOptions);  // Check selectedOptions

    // Handle hillside: Only 0 for "No"
    if (filter.selectedOptions[0] === 1) {
      // Yes: Filter for 1
      finalFilter = ["==", ["get", key], 1];
    } else {
      // No: Only filter for 0 (do not handle null here)
      finalFilter = ["==", ["get", key], 0];
    }
  }

  console.log("Final filter created:", finalFilter);  // Log final filter expression
  return finalFilter;
};

// Apply filters to the map
export const applyFilters = (filters, map, setPropertyData) => {
    if (!map) return;

    const filterExpressions = Object.keys(filters).map((key) => {
      const filter = filters[key];
      if (filter.applied) {
        let geoJSONKey = key;
        
        // Use the new utility function to generate the filter expression
        return createFilterExpression(filter, geoJSONKey);
      }
      return null;
    }).filter(Boolean);

    const finalExpression = filterExpressions.length > 0 ? ['all', ...filterExpressions] : null;

    // Apply the filters to the map layers
    map.setFilter('sql_statement_dots', finalExpression);
    map.setFilter('sql_statement_symbols', finalExpression);

    // Once the map is idle, update the property data with visible features
    map.once('idle', () => {
      const visibleFeatures = map.queryRenderedFeatures({ layers: ['sql_statement_dots', 'sql_statement_symbols'] });
      const features = visibleFeatures.map((feature) => feature.properties);
      setPropertyData(features);
    });
};

// Reset a filter on the map
export const resetFilter = (key, map) => {
    if (!map) return;

    // Reset the filter to show all features
    map.setFilter('sql_statement_dots', null);
    map.setFilter('sql_statement_symbols', null);
};
