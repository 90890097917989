import numeral from 'numeral';

export const formatValue = (value, format = '0,0', prefix = '', suffix = '', dataType, fieldName, row) => {
  // Return 'N/A' for null or empty values
  if (value == null || value === '') return 'N/A';

  // Handle 'situsaddre' field with Zillow link logic
  if (fieldName === 'situsaddre') {
    const zillowLink = row?.['search_link']; // Safe navigation to handle missing row or link
    return zillowLink ? <a href={zillowLink} target="_blank" rel="noopener noreferrer">{value}</a> : value;
  }

  // Format numerical values for 'Double' or 'Integer' data types
  if (dataType === 'Double' || dataType === 'Integer') {
    const formattedValue = numeral(value).format(format); // Apply default format
    return `${prefix}${formattedValue}${suffix}`; // Include prefix and suffix
  }

  // Return 'Yes' or 'No' for Boolean values
  if (dataType === 'Boolean') {
    return value ? 'Yes' : 'No';
  }

  // Default: Return the value as-is for String or other data types
  return value;
};
