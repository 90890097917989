// src/usePropertyData.js

import { useEffect, useState } from 'react';
import Papa from 'papaparse';

/**
 * Custom hook for fetching and grouping property data from a CSV file.
 * Parses CSV data, groups it by category, and provides data states.
 */
export function usePropertyData() {
  const [data, setData] = useState([]);
  const [groupedData, setGroupedData] = useState({});

  // 01.00 - Fetch and parse CSV data
  useEffect(() => {
    Papa.parse('ColumnsNames-ColumnsNames.csv', {
      download: true,
      header: true,
      complete: (result) => {
        setData(result.data);
        groupData(result.data);
      },
    });
  }, []);

  // 02.00 - Group the data by Category
  const groupData = (data) => {
    const grouped = data.reduce((acc, item) => {
      if (item['Field Name'] !== 'search_lin') {  // Exclude Zillow Link column
        if (!acc[item.Category]) {
          acc[item.Category] = [];
        }
        acc[item.Category].push(item);
      }
      return acc;
    }, {});
    setGroupedData(grouped);
  };

  return { data, groupedData };
}
